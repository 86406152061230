import { Link } from "react-router-dom";
function Header() {
    return (
      <>
      <div className="logo-box">
        {/* <Link to='/' className="logo">
          <img src='/img/logo.jpg' alt='logo' />
        </Link> */}

        <div className="top_fix">
          <span className="logo">
            <img src="/img/logo.jpg" alt="Satara Police" title="Satara Police"/>
          </span>
          <h3 className="top_bar" title="Satara Police" style={{marginTop:'5px!important'}}><b>Satara Police</b></h3>
        </div>

        <h5 className="heading">E-Service Sheet</h5>
      </div>        
      </>
    );
  }
  
  export default Header;
  