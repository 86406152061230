import Clients from "./clients/Clients";
import axios from "axios";
import appConstant from "./constant/constant.json";
import {useEffect, useState} from "react";
function Footer() {
    const [visitorsData, setVisitorsData] = useState('');
    useEffect(() => {
        visitors();
    }, []);
    function visitors() {
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        axios.get(`${appConstant.API_URL}/user/visitors`, params)
            .then(res => {
                if(res?.data?.status === 200){
                    setVisitorsData(res?.data?.data?.visitors);
                }
            })
            .catch(error => {
            });
    }
    return (
      <>
        <Clients/>
        <div className="flex-box">
          <span className="footer-column">Help Line: <a href="tel:02162233833">02162-233833</a></span>
          <span className="footer-column">Control Room: 112</span>
        </div>
        <hr/>
        <div className="flex-box">
          <span className="footer-column">Copyright &copy; 2023 Satara Police</span>
          <span className="footer-column">Visitors: {visitorsData}</span>
        </div>
      </>
    );
  }

  export default Footer;
