import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useNavigate, useLocation} from "react-router-dom";
import appConstant from "./shared/constant/constant.json";
import Header from "./shared/Header";
import Footer from "./shared/Footer";

function Varify() {
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        sendOTP();
    }, []);
    const nav = useNavigate();
    const location = useLocation();
    console.log(location);
    const [inputValues, setInputValue] = useState({
        otp: ''
    });
    const [validation, setValidation] = useState({
        otp: ''
    });
    const [resultMessage, setResultMessage] = useState('');
    if(location.state === null){
        nav('/');
    }
    function handleChange(e) {
        const { name, value } = e.target;
        setInputValue({ ...inputValues, [name]: value });
    }

    const checkValidation = () => {
        let errors = JSON.parse(JSON.stringify(validation));
        //otp validation
        if (!inputValues.otp) {
            errors.otp = "OTP is required";
        }else {
            errors.otp = "";
        }
        setValidation(errors);
        if(errors.otp === ''){
            confirmOTP();
        }else{
            return false;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        checkValidation();
    };

    const sendOTP = () => {
        const sendOtpData = new FormData();
        sendOtpData.append('sevarth_id',location.state?.sevarthId);
        sendOtpData.append('is_change_password',location.state?.changePassword ? 'yes' : 'no');
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        axios.post(`${appConstant.API_URL}/user/send_otp`, sendOtpData, params)
            .then(res => {
                if (res.data.status === 200) {
                    setResultMessage('OTP Sent.')
                }else{
                    setResultMessage(res.data.message);
                }
            })
            .catch(error => {
            });
    }
    const confirmOTP = () => {
        setIsLoading(true);
        const params = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
        const checkOtpData = new FormData();
        checkOtpData.append('sevarth_id',location.state?.sevarthId);
        checkOtpData.append('otp', inputValues.otp);
        axios.post(`${appConstant.API_URL}/user/check_otp`, checkOtpData, params)
            .then(res => {
                setIsLoading(false);
                if (res?.data?.status === 200) {
                    localStorage.setItem("token", res.data?.data?.token);
                    if(res?.data?.data?.is_first_time === '1'){
                        nav('/change-password');
                    }else{
                        window.location.href = '/dashboard';
                    }
                }else if (res?.data?.status === 400) {
                    setResultMessage(res?.data?.message);
                }
            })
            .catch(error => {
                setIsLoading(false);
            });
    }
  return (
    <>
      <Header/>
      <div className="content">
        <div className="form-box">
            {/* <p className='formErrors' style={{color: 'green'}}>Successfully sent.</p> */}
            {resultMessage && <h5 style={{color: 'green'}}>{resultMessage}</h5>}
          <label className="label">OTP</label>
          <input type='text' className="inputs" name="otp" placeholder="Enter OTP" value={inputValues.otp} onChange={(e) => handleChange(e)} />
            {validation.otp && <p className='formErrors' style={{textAlign: "center"}}>{validation.otp}</p>}
          <div className="flex-box">
            <button className="gray-btn">Reset</button>
            <button className="blue-btn" onClick={(e) => {handleSubmit(e)}}>Submit {isLoading ? <img src="img/loader.gif" alt="loader" style={{marginLeft:'10px',width:'10px',}}/> : '' }</button>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}

export default Varify;
